table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  #subscription{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    display:block;
    overflow-x:auto;
    white-space: nowrap;
    text-align: center; 
  }
  #instructor{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    display:block;
    overflow-x:auto;
    white-space: nowrap;
    text-align: center; 
  }
  
  table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  table tr:nth-child(even){background-color: #f2f2f2;}
  
  table tr:hover {background-color: #ddd;}
  
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: rgb(150,22,22);
    color: white;
  }

  #prevnext{
    float: right;
  }

  