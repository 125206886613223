#misc_comp{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

#divi{
    width: 100%;
}