#headerPartPartnerEdit{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderPartnerEdit{
  width: 100vw;
  font-size: 50;
  height: 60px;
}



#profilePictureChangePartnerEdit{
  width: 90vw;
  margin-left: 5vw;

}



#partnerEdit_button1{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#partnerEdit_button1:hover{
  background-color:#9c0303;
}

.formHalfWidthPartnerEdit{
  width: 50%;
}
.fullWidthFormPartnerEdit{
  width: 100%;
}

#submitPartnerEditContainer{
    display: flex;
    flex-direction:row;
    text-align: center;
}


#submitPartnerEdit1{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: rgb(150,22,22);
}

#submitPartnerEdit1:hover{
  background-color:#9c0303;
}

#submitPartnerEdit2{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: rgb(150,22,22);
}

#submitPartnerEdit2:hover{
  background-color:#9c0303;
}

#submitPartnerEdit3{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: rgb(150,22,22);
}

#submitPartnerEdit3:hover{
  background-color:#9c0303;
}

#submitPartnerEdit4{
  width: 100%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-top: 1%;
  background: rgb(150,22,22);
}

#submitPartnerEdit4:hover{
  background-color:#9c0303;
}

#addFormPartnerEdit{
  margin: 5vw;
  margin-top: 0;
  width: 90vw;
}

#addPartnerEdit{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}



@media (orientation: landscape){
  #formPartnerEdit{
    width: 90%;
    color: white;
    font-size:1.3vw;
  }
  #containerPartnerEdit{
    display: flex;
    flex-direction: row;
    float: left;
    margin-left: 5vw;
  }
  
  #cardPartnerEdit{
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    margin-top: 2%;
  }
  
  #addFormPartnerEdit{
    width: 75vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media (orientation: portrait){
  #formPartnerEdit{
    width: 90vw;
    color: white;
    font-size:2.5vw;
  }
  #containerPartnerEdit{
    display: flex;
    flex-direction: column;
  }
  
  #cardPartnerEdit{
    width: 200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #addFormPartnerEdit{
    width: 90vw;
    margin-left: 5vw;
  }
}


#partner_reset_password{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#partner_reset_password:hover{
  background-color:#9c0303;
}


#resetPasswordPartnerEdit{
  margin: 5vw;
  width: 90vw;
}

#resetPartnerEdit{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}

#formResetPasswordPartner{
  color: white;
}

#submitResetPasswordPartner{
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: rgb(150,22,22);
}