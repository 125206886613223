#headerPartStudentEdit{
    width: 100vw;
    height: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  #HeaderStudentEdit{
    width: 100vw;
    font-size: 50;
    height: 60px;
  }
  
  
  
  #profilePictureChangeStudentEdit{
    width: 90vw;
    margin-left: 5vw;
  
  }
  
  
  
  #studentEdit_button1{
    margin: 2px;
    background-color:rgb(150,22,22);
    width: 180px;
    font-size: larger;
    margin-left: 5vw;
  }
  
  #studentEdit_button1:hover{
    background-color:#9c0303;
  }
  
  .formHalfWidthStudentEdit{
    width: 50%;
  }
  .fullWidthFormStudentEdit{
    width: 100%;
  }
  
  #submitStudentEditContainer{
      display: flex;
      flex-direction:row;
      text-align: center;
  }
  
  
  #submitStudentEdit1{
    width: 31%;
    color: white;
    font-size: larger;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    background: rgb(150,22,22);
  }
  
  #submitStudentEdit1:hover{
    background-color:#9c0303;
  }
  
  #submitStudentEdit2{
    width: 31%;
    color: white;
    font-size: larger;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    background: rgb(150,22,22);
  }
  
  #submitStudentEdit2:hover{
    background-color:#9c0303;
  }
  
  #submitStudentEdit3{
    width: 31%;
    color: white;
    font-size: larger;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    background: rgb(150,22,22);
  }
  
  #submitStudentEdit3:hover{
    background-color:#9c0303;
  }

  #submitStudentEdit4{
    width: 100%;
    color: white;
    font-size: larger;
    padding: 1%;
    margin-top: 1%;
    background: rgb(150,22,22);
  }
  
  #submitStudentEdit4:hover{
    background-color:#9c0303;
  }
  
  #addFormStudentEdit{
    margin: 5vw;
    margin-top: 0;
    width: 90vw;
  }
  
  #addStudentEdit{
    margin: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color:#262626;
  }
  
  
  @media (orientation: landscape){
    #formStudentEdit{
      width: 90%;
      color: white;
      font-size:1.3vw;
    }
    #containerStudentEdit{
      display: flex;
      flex-direction: row;
      float: left;
      margin-left: 5vw;
    }
    
    #cardStudentEdit{
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    #addFormStudentEdit{
      width: 75vw;
      margin-left: 5vw;
      margin-right: 5vw;
    }
  }
  
  @media (orientation: portrait){
    #formStudentEdit{
      width: 90vw;
      color: white;
      font-size:2.5vw;
    }
    #containerStudentEdit{
      display: flex;
      flex-direction: column;
    }
    
    #cardStudentEdit{
      width: 200px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    #addFormStudentEdit{
      width: 90vw;
      margin-left: 5vw;
    }
  }
  
  
  #student_reset_password{
    margin: 2px;
    background-color:rgb(150,22,22);
    width: 180px;
    font-size: larger;
    margin-left: 5vw;
  }
  
  #student_reset_password:hover{
    background-color:#9c0303;
  }


  #resetPasswordStudentEdit{
    margin: 5vw;
    width: 90vw;
  }
  
  #resetStudentEdit{
    margin: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color:#262626;
  }
  
  #formResetPasswordStudent{
    color: white;
  }
  
  #submitResetPasswordStudent{
    margin-top: 2%;
    margin-left: 80%;
    color: white;
    font-size: larger;
    padding: 1%;
    background: rgb(150,22,22);
  }