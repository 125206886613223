#existingTable{
  width: 90vw;
  margin: auto;
}

#submitViewSalary{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#submitViewSalary:hover{
  background-color:#9c0303;
}

#submitDeleteSalary{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#submitDeleteSalary:hover{
  background-color:#9c0303;
}