.admin_schedule.e-schedule.e-device .e-vertical-view .e-left-indent,
.admin_schedule.e-schedule.e-device .e-vertical-view .e-time-cells-wrap {

  width: 50px

}

#headerPartStudMySchedule{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#scheduleHeaderStudMySchedule{
  width: 100vw;
  font-size: 50;
  height: 60px;
}

.date{
  width: 45%;
  margin-left: 2%;
  margin-right: 2%;
}
.form2{
  width: 45%;
  margin-left: 2%;
  margin-right: 2%;
}

.form1{
  width: 27.7%;
  margin-left: 2%;
  margin-right: 2%;
}

.body{
  margin: 0px;
}

.Dropdown{
  width: 100%;
}

#group{
  color: white;
  text-align: center;
  text-size-adjust:inherit;
  word-wrap: break-word;
  background: #740303;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  /* font-size: 13px;
  font-weight: 500;*/
}

#fixed_rent{
  color: white;
  text-align: center;
  background: #4817a1;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
 /* font-size: 13px;
  font-weight: 500;*/
}

#rent{
  color: white;
  text-align: center;
  background: #1d024e;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
 /* font-size: 13px;
  font-weight: 500;*/
}


#fixed_reserve{
  color: white;
  text-align: center;
  background: #8425a1;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
 /* font-size: 13px;
  font-weight: 500;*/
}

#reserve{
  color: white;
  text-align: center;
  background: #5c0d51;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
 /* font-size: 13px;
  font-weight: 500;*/
}

#fixed_private{
  color: white;
  text-align: center;
  background: #99970f;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
 /* font-size: 13px;
  font-weight: 500;*/
}

#private{
  color: white;
  text-align: center;
  background: #424141;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  /* font-size: 13px;
  font-weight: 500;*/
}

#workshop{
  color: white;
  text-align: center;
  background: #013a1b;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  /* font-size: 13px;
  font-weight: 500;*/
}

/*Room1*/
#room1{
  color: white;
  text-align: center;
  text-size-adjust:inherit;
  word-wrap: break-word;
  background: rgb(1, 87, 87);
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  /* font-size: 13px;
  font-weight: 500;*/
}

/*Room2*/
#room2{
  color: white;
  text-align: center;
  text-size-adjust:inherit;
  word-wrap: break-word;
  background: rgb(2, 2, 85);
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  /* font-size: 13px;
  font-weight: 500;*/
}


/*Room3*/
#room3{
  color: white;
  text-align: center;
  text-size-adjust:inherit;
  word-wrap: break-word;
  background: rgb(131, 3, 131);
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  /* font-size: 13px;
  font-weight: 500;*/
}
/*Room4*/
#room4{
  color: white;
  text-align: center;
  text-size-adjust:inherit;
  word-wrap: break-word;
  background: rgb(119, 1, 1);
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  /* font-size: 13px;
  font-weight: 500;*/
}

/*Room5*/
#room5{
  color: white;
  text-align: center;
  text-size-adjust:inherit;
  word-wrap: break-word;
  background: rgb(82, 82, 2);
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  /* font-size: 13px;
  font-weight: 500;*/
}

/*Room6*/
#room6{
  color: white;
  text-align: center;
  text-size-adjust:inherit;
  word-wrap: break-word;
  background: rgb(1, 155, 1);
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  /* font-size: 13px;
  font-weight: 500;*/
}


/* end of rooms */

@media (orientation: landscape) {
  #legends_stud_myschedule{
    display: flex;
    flex-direction: row;
    width: 95vw;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    position: relative;
  }

  #room_legend_stud_myschedule_1{
    font-size: large;
    width: 100px;
    height: 50px;
    padding-top: 15px;
    text-align: center;
  }
  
  #room_legend_stud_myschedule_2{
    font-size: large;
    width: 100px;
    height: 50px;
    padding-top: 15px;
    text-align: center;
  }
  
  #room_legend_stud_myschedule_3{
    font-size: large;
    width: 100px;
    height: 50px;
    padding-top: 15px;
    text-align: center;
  }
  
  #room_legend_stud_myschedule_4{
    font-size: large;
    width: 100px;
    height: 50px;
    padding-top: 15px;
    text-align: center;
  }
  
  #room_legend_stud_myschedule_5{
    font-size: large;
    width: 100px;
    height: 50px;
    padding-top: 15px;
    text-align: center;
  }
  
  #room_legend_stud_myschedule_6{
    font-size: large;
    width: 100px;
    height: 50px;
    padding-top: 15px;
    text-align: center;
  }
  
}

#cont_block_stud_myschedule{
  display: block;
}


@media (orientation: portrait) {
  #legends_stud_myschedule{
    display: flex;
    flex-direction:row;
    position: relative;;
    font-size: medium;
    width: 100vw;
    height: 6vw;
  }
  
  #room_legend_stud_myschedule_1{
    width: 16vw;
    font-size: small;
    width: 20vw;
    height: 6vw;
    padding-top: 1.5%;
    text-align: center;
  }
  
  #room_legend_stud_myschedule_2{
    width: 16vw;
    font-size: small;
    width: 20vw;
    height: 6vw;
    padding-top: 1.5%;
    text-align: center;
  }
  
  #room_legend_stud_myschedule_3{
    width: 16vw;
    font-size: small;
    width: 20vw;
    height: 6vw;
    padding-top: 1.5%;
    text-align: center;
  }
  
  #room_legend_stud_myschedule_4{
    width: 16vw;
    font-size: small;
    width: 20vw;
    height: 6vw;
    padding-top: 1.5%;
    text-align: center;
    background-color:#99970f;
  }
  
  #room_legend_stud_myschedule_5{
    width: 16vw;
    font-size: small;
    width: 20vw;
    height: 6vw;
    padding-top: 1.5%;
    text-align: center;
  }
  
  #room_legend_stud_myschedule_6{
    width: 16vw;
    font-size: small;
    width: 20vw;
    height: 3vw;
    padding-top: 1.5%;
    text-align: center;
  }
}



#legend_1_stud_myschedule{
  color: rgb(1, 87, 87);
}

#legend_2_stud_myschedule{
  color: rgb(2, 2, 85);
}

#legend_3_stud_myschedule{
  color: rgb(131, 3, 131);
}

#legend_4_stud_myschedule{
  color: rgb(119, 1, 1);
}

#legend_5_stud_myschedule{
  color: rgb(82, 82, 2);
}

#legend_6_stud_myschedule{
  color: rgb(1, 155, 1);
}

.e-schedule .e-appointment-details{
  padding: 0 !important;
}


#newclass_button{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:rgb(150,22,22);
}
#rent_reserve_button{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:rgb(150,22,22);
}

#per_room_button1{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:#740303;
}

#per_room_button2{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:#99970f;
}

#per_room_button3{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:#424141;
}

#per_room_button4{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:#013a1b;
}

#per_room_button5{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:#4817a1;
}

#per_room_button6{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:#1d024e;
}

#per_room_button7{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:#8425a1;
}

#per_room_button8{
  margin: 2px;
  color: white;
  text-align: center;
  background-color:#5c0d51;
}

#submit{
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: rgb(150,22,22);
}

#form-div{
  margin-left: 2%;
  margin-right: 4%;
  width: 500 px;
}

#form-div-newclass{
  margin-left: 2%;
  margin-right: 4%;
  width: 500 px;
}

#form-div-rent-reserve{
  margin-left: 2%;
  margin-right: 4%;
  width: 500 px;
}

#add_class{
  margin: 1%;
  width: 100%;
  color: white;
  background-color:#292929;
}

#rent_reserve{
  margin: 1%;
  width: 100%;
  color: white;
  background-color:#292929;
}

#form{
  margin: 1%;
  width: 95%;
}

.label_css {
  color: rgb(255,255,255);
  font-size: large;
  margin-bottom: 5px;
  margin-top: 5px;
}


@media only screen 
  /*and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)*/
  and (orientation: portrait) {

    .label_css {
      color: rgb(255,255,255);
      font-size:xx-small;
    }
    .Dropdown{
      font-size:xx-small;
    }
    input{
      font-size:xx-small;
    }
    #input{
      font-size:xx-small;
    }
}
DateTimePickerComponent{
  background-color: #FFFFFF;
}


#input{
  width: 100%;
}

/*
.button{
  margin: 20px;
  padding: 5px;
  width: 25%;
  align-content: right;
  float:right;
  grid-area:btn;
  background-color: red;
  color: white;
}

.button:hover{
  margin: 20px;
  padding: 5px;
  width: 25%;
  align-content: right;
  float:right;
  grid-area:btn;
  background-color: rgb(109, 2, 2);
  color: white;
}
form{
  display:flex;
}
label { color: #000000; font-weight: bold; display: block; width: 30%; float: left; } label:after { content: ": " }

input{
  width: 60%;
}

select{width: 60%;}
*/

@media (orientation: landscape){
  #class_attendance_info_stud_myschedule_button{
    background-color:rgb(150,22,22);
    color: white;
    width: 90%;
    height: 90%;
    text-align: center;
    font-size: small;
    font-weight: bold;
    cursor: pointer;
    border-style: none;
    border-radius: 5%;
  }
  #class_attendance_info_stud_myschedule_button:hover{
    background-color:#9c0303;
  
  }
  
  #class_attendance_info_stud_myschedule{
    display: flex;
    flex-direction: row;
    width: 95vw;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    position: relative;
  }
  
  .itemScheduleActionsStudMySchedule{
    width: 15vw;
    height: 50px;
    background-color: white;
    padding: 0.5%;
    padding-top: 0.7%;
  }
}

@media (orientation: portrait){
  #class_attendance_info_stud_myschedule_button{
    background-color:rgb(150,22,22);
    color: white;
    width: 90%;
    height: 90%;
    text-align: center;
    font-size: 2.1vw;
    font-weight: bold;
    cursor: pointer;
    border-style: none;
    border-radius: 5%;
  }
  #class_attendance_info_stud_myschedule_button:hover{
    display: block;
    background-color:#9c0303;
    position: relative;
  }
  
  #class_attendance_info_stud_myschedule{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 95vw;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }
  
  .itemScheduleActionsStudMySchedule{
    width: 20vw;
    height: 12vw;
    background-color: white;
    padding: 0.5%;
    padding-top: 0.7%;
  }
}



#canceled_slot{
  color: white;
  text-align: center;
  background: #c70505;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 7px;
  padding-bottom: 100%;
  font-size:90%;
  content: "\A";
  white-space: nowrap;
  text-overflow: ellipsis;
}

#submit_pay_rent{
  margin: 2px;
  background-color:rgb(150,22,22);
}

#submit_pay_rent:hover{
  margin: 2px;
  background-color:#9c0303;
}

#submit_pay_div{
  padding: 5px;
  padding-bottom: 5%;
}

#rent_info_pay{
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  margin: 20px;
}

#submit_hide_rent{
  margin: 2px;
  background-color:rgb(150,22,22);
}

#submit_hide_rent:hover{
  margin: 2px;
  background-color:#9c0303;
}

#submit_hide_rent_div{
  padding: 5px;
  padding-bottom: 5%;
}

#rent_info_view{
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  margin: 20px;
}

@media (orientation: portrait){
        
  .e-time-slots{
    font-size: 1;
  }
  .e-time-cells-wrap{
    width: 20vw;
  }
  .e-schedule-table{
    width: 20vw;
  }

  .e-schedule-table .e-time-cells-wrap{
    width: 20vw;
  }
  .e-content-wrap{
    position: relative;
    float: right;
    width: 90vw;
  }
  .e-schedule-table{
    width: 100%;
  }
  .e-content-table{
    width: 100%;
  }
}