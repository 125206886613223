#headerPartAttendanceStudRemove{
  width: 100%;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderAttendanceStudRemove{
  width: 100%;
  font-size: 50;
  height: 60px;
}

#tableStudAttRemove{
  width: 100%;
  margin: auto;
}


#attendanceStudRemove_button1{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 100%;
  font-size: large;
}

#attendanceStudRemove_button1:hover{
  background-color:#9c0303;
}

