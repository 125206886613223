#menu_id{
    position: relative;
    justify-content: center;
    background: rgb(150,22,22);
    color: #FFFFFF;
    margin: 0%;
    text-align: center;
    width: 100vw;
    height:500px;
}

#CLASSES{
    justify-content: center;
    background: rgb(150,22,22);
    width: 33.3vw;
    /*height: 1vw;*/
    color: #FFFFFF;
    text-align: center;
    padding-top: 2%;
    font-size: 4vw;
    font-weight: bold;
    cursor: pointer;
}

#CLASSES:hover{
    justify-content: center;
    background: rgba(255, 255, 255, 0.152);
}

#PACKAGES{
    justify-content: center;
    background: rgb(150,22,22);
    width: 33.3vw;
    /*height: 1vw;*/
    color: #FFFFFF;
    text-align: center;
    padding-top: 2%;
    font-size: 4vw;
    font-weight: bold;
    cursor: pointer;
}

#PACKAGES:hover{
    justify-content: center;
    background: rgba(255, 255, 255, 0.152);
}

#SCHEDULE{
    justify-content: center;
    background: rgb(150,22,22);
    width: 33.3vw;
    /*height: 1vw;*/
    color: #FFFFFF;
    text-align: center;
    padding-top: 2%;
    font-size: 4vw;
    font-weight: bold;
    cursor: pointer;
}

#SCHEDULE:hover{
    justify-content: center;
    background: rgba(255, 255, 255, 0.152);
}