#PackagesBackground{
    width: 100vw;
    background-image: url("../media/Images/redWithWhiteCircles.png");
    background-color: rgb(150, 22, 22);
    background-repeat: repeat;
    object-fit: contain;
    object-position: left;
    /*background-color: rgb(150, 22, 22);*/
}

#packagesPart{
    height: 70px;
    float:right;
}

#doubleCircles{
    height: 20vw;
}


.header {
    background: linear-gradient(0deg, rgb(150, 22, 22) 69.50097%, #FFFFFF 0%);
    height: 100px;
    width: 100%;
    float:right;
    margin-left: 1vw;
  }
  
  .containerP{
    display: flex;
    flex-flow: row wrap;
    justify-content:space-evenly;
    text-align: center;
    width: 100%;
    height: 40vw;
    padding: 1vw;
    padding-left: 1vw;
    padding-right: 1vw;
    list-style: none;
}

.itemP {
    flex-basis:  auto; /* default auto */
    padding: 1vw;
    width: 50%;
    height: 100%;
    line-height: 1.7vw;
    background-color: rgba(125, 22, 22, 0.57);
    font-weight: bold;
    font-size: 50vw;
    text-align: left;
    color: white;
    background-size: cover;
    margin-bottom: 20px;
}

.h1{
    font-size: 3vw;
}
.textArea{
    background-color: rgba(255, 255, 255, 0%);
    color: rgba(255, 255, 255, 100%);
    width: 45vw;
    height: 90%;
    line-height: 2.8vw;
    font-size: 1.7vw;
    border-style: none;
    
}