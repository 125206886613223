
@media (orientation: landscape) {
    #slide{
        height: calc(100vw *1032 / 1916);
        width: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
        object-fit:cover;
        object-position: center;
        background-position: center;
        object-position: center;
        background-size: 50% auto;
        background-color: black;
    }
    
    .each-slide > div {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-size: cover;
        width: 100%;
        height: auto;
        height: calc(100vw *1032 / 1916);
        object-fit: cover;
        object-position: center;
        background-position: center;
        background-size: 50% auto;
        background-color: black;
    }
      
    .each-slide span {
        padding: 20px;
        font-size: 20px;
        background: #efefef;
        width: 100%;
        height: calc(100vw *1032 / 1916);
        object-fit: cover;
        object-position: center;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-size: 50% 100%;
        background-color: black;
    }
    
    .fullscreen-bg__video{
        height: calc(100vw *1032 / 1916);
        width: 100%;
        object-fit: cover;
        object-position: center;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-position: center;
        background-size: 50% auto;
        background-color: black;
    }
    .srcx{
        width: 100%;
    }
}

@media (orientation: portrait) {
    #slide{
        height: calc(100vw *1032 / 1916);
        width: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
        object-fit: cover;
        object-position: center;
        background-position: center;
        object-position: center;
        background-size: 50% auto;
        background-color: black;
    }
    
    .each-slide > div {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-size: cover;
        width: 100%;
        height: auto;
        height: calc(100vw *1032 / 1916);
        object-fit: cover;
        object-position: center;
        background-position: center;
        background-size: 50% auto;
        background-color: black;
    }
      
    .each-slide span {
        padding: 20px;
        font-size: 20px;
        background: #efefef;
        width: 100%;
        height: calc(100vw *1032 / 1916);
        object-fit: cover;
        object-position: center;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-size: 50% 100%;
        background-color: black;
    }
    
    .fullscreen-bg__video{
        height: calc(100vw *1032 / 1916);
        width: 100%;
        object-fit: cover;
        object-position: center;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-position: center;
        background-size: 50% auto;
        background-color: black;
    }
    .srcx{
        width: 100%;
    }
    
}



