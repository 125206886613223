  
  #HeaderDetailedStudSubscription{
    width: 90vw;
    margin: auto;
  }
  
  #tableDetailedStudSubscription{
    width: 90vw;
    margin: auto;
  }

  #editButtons{
    width: 90vw;
    margin: auto;
  }
  
  #detailedStudSubscription_button1{
    margin: 2px;
    background-color:rgb(150,22,22);
    width: 180px;
    font-size: larger;
    margin-left: 5vw;
  }
  
  #detailedStudSubscription_button1:hover{
    background-color:#9c0303;
  }
  
  .formHalfWidthDetailedStudSubscription{
    width: 50%;
  }
  .fullWidthFormDetailedStudSubscription{
    width: 100%;
  }
  
  
  #submitDetailedStudSubscription{
    margin-top: 2%;
    margin-left: 80%;
    color: white;
    font-size: larger;
    padding: 1%;
    background: rgb(150,22,22);
  }
  
  #addFormDetailedStudSubscription{
    margin: 5vw;
    width: 90vw;
  }
  
  #addDetailedStudSubscription{
    margin: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color:#262626;
  }
  
  #formDetailedStudSubscription{
    color: white;
  }
  
  @media (orientation: landscape){
    #formDetailedStudSubscription{
      width: 90%;
      color: white;
      font-size:1.3vw;
    }
  }
  
  @media (orientation: portrait){
    #formDetailedStudSubscription{
      width: 90%;
      color: white;
      font-size:2.5vw;
    }
  }
  
  