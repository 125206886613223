#headerPartRoom{
    width: 100vw;
    height: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  #HeaderRoom{
    width: 100vw;
    font-size: 50;
    height: 60px;
  }
  
  #tableRoom{
    width: 90vw;
    margin: auto;
  }
  
  #room_button1{
    margin: 2px;
    background-color:rgb(150,22,22);
    width: 180px;
    font-size: larger;
    margin-left: 5vw;
  }
  
  #room_button1:hover{
    background-color:#9c0303;
  }
  
  .formHalfWidthRoom{
    width: 50%;
  }
  .fullWidthFormRoom{
    width: 100%;
  }
  
  
  #submitRoom{
    margin-top: 2%;
    margin-left: 80%;
    color: white;
    font-size: larger;
    padding: 1%;
    background: rgb(150,22,22);
  }
  
  #addFormRoom{
    margin: 5vw;
    width: 90vw;
  }
  
  #addRoom{
    margin: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color:#262626;
  }
  
  #formRoom{
    color: white;
  }
  
  @media (orientation: landscape){
    #formRoom{
      width: 90%;
      color: white;
      font-size:1.3vw;
    }
  }
  
  @media (orientation: portrait){
    #formRoom{
      width: 90%;
      color: white;
      font-size:2.5vw;
    }
  }
  
  