*{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  box-sizing: border-box;
}

#container{
  display:inline;
  text-align: center;
  align-self: center;
  align-items: center;
}