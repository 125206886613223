#headerPartClosing{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderClosing{
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableClosing{
  width: 90vw;
  margin: auto;
}

.closing_button_container{
  width: 90vw;
  margin-left: 5vw;
}

#closing_button1{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 250px;
  font-size: large;
  margin-left: 3vw;
}

#closing_button1:hover{
  background-color:#9c0303;
}

#closing_button2{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 250px;
  font-size: large;
  margin-left: 3vw;
}

#closing_button2:hover{
  background-color:#9c0303;
}