#headerPartPaymentMiscs{
    width: 90vw;
    margin-left: 5vw;
    height: 30px;
    text-align: center;
}
  
#HeaderPaymentMiscs{
    height: 50px;
    width: 300px;
    font-size: 50;
    background-color: white;
    color: black;
    justify-content: center;
    text-align: center;
    padding: 5px;
}
  
#iconPaymentMiscs{
    display: inline;
    width:50px;
    padding-right: 5px;
    justify-self: center;
}

#tablePaymentMiscs{
    width: 90vw;
    margin: auto;
}
  