#headerPartMisc{
    width: 100vw;
    height: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  #HeaderMisc{
    width: 100vw;
    font-size: 50;
    height: 60px;
  }
  
  #tableMisc{
    width: 90vw;
    margin: auto;
  }
  
  #misc_button1{
    margin: 2px;
    background-color:rgb(150,22,22);
    width: 180px;
    font-size: larger;
    margin-left: 5vw;
  }
  
  #misc_button1:hover{
    background-color:#9c0303;
  }
  
  .formHalfWidthMisc{
    width: 50%;
  }
  .fullWidthFormMisc{
    width: 100%;
  }
  
  
  #submitMisc{
    margin-top: 2%;
    margin-left: 80%;
    color: white;
    font-size: larger;
    padding: 1%;
    background: rgb(150,22,22);
  }
  
  #addFormMisc{
    margin: 5vw;
    width: 90vw;
  }
  
  #addMisc{
    margin: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color:#262626;
  }
  
  #formMisc{
    color: white;
  }
  
  @media (orientation: landscape){
    #formMisc{
      width: 90%;
      color: white;
      font-size:1.3vw;
    }
  }
  
  @media (orientation: portrait){
    #formMisc{
      width: 90%;
      color: white;
      font-size:2.5vw;
    }
  }
  
  