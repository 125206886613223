div{
    width: 100%;
}
#menu_id{
    background: rgba(150, 22, 22, 1);
    color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 60px;
    text-align: center;
    font-size: x-large;
    font-weight: bolder;
    margin: 0px;
}

#lines_options{
    background-color: rgba(150, 22, 22, 0);
    color: white;
    height: 70px;
}
#lines_option{
    color: white;
    height: 70px;
}
#lines_option:hover{
    background-color: rgba(150, 22, 22, 0.5);
}
.menu_item{
    margin: 0px;
    height: 60px;
    font-size: x-large;
    font-weight: bolder;
    text-align: center;
}
.menu_item:hover{
    margin: 0px;
    height: 60px;
    font-size: x-large;
    font-weight: bolder;
    text-align: center;
}

#sign-in-text{
    display: flex;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-self:center;
    justify-content: center;
    font-size: x-large;
    font-weight: bolder;
}

#sign-in-text:hover{
    margin: 0px;
    text-align: center;
    padding-left: 10px;
    font-size: x-large;
    font-weight: bolder;
}

#menu_2_id{
    margin: 0px;
    background: rgb(31, 26, 26);
    color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 100px;
    font-size: small;
    /*border: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);*/
}


#menu_2_id_phone_part_inst{
    margin: 0px;
    width: 100%;
    font-size: medium;
    background: rgb(31, 26, 26);
    color: rgba(255, 255, 255, 1);

}


.menu_2_item{
    margin: 0px;
    height: 100px;
    font-size: large;
    
}

.drop_menu_2_item{
    font-size: large;
    width: 50%;
}

/*#menu_item_id{
    background: rgba(150, 22, 22, 1);
    color: rgba(255, 255, 255, 1);
}*/

/*#logoImg{
    height: 30px;
    width: 30px;
}*/


@media (orientation: landscape) /*laptops*/
{
    #menu_2_id_phone_part_inst{
        display: none;
    }
}

@media (orientation: portrait) /*Phone*/
{
    .menu_item{
        margin: 0px;
        height: 120px;
        font-size: x-large;
        font-weight: bolder;
        text-align: center;
    }
    .menu_item:hover{
        margin: 0px;
        height: 120px;
        font-size: x-large;
        font-weight: bolder;
        text-align: center;
    }

    #sign-in-text{
        margin: 0px;
        text-align: center;
        padding-left: 10px;
        font-size: x-large;
        font-weight: bolder;
    }
    
    #sign-in-text:hover{
        margin: 0px;
        text-align: center;
        padding-left: 10px;
        font-size: x-large;
        font-weight: bolder;
    }
    
    #menu_2_id{
        display: none;
    }
    .menu_2_item{
        margin: 0px;
        width: 50px;
        font-size: large;
    }
    .menu_2_item:hover{
        margin: 0px;
        width: 50px;
        font-size: large;
    }
}


#dotPng_style{
    height: 30px;
    width: 15px;
    margin-right: 10px;
    margin-left: 10px;
}

#id_text{
    width: auto;
}
/*
#dot_style{
    font-size: xx-large;
    font-weight: bold;
    color: #FFFFFF;
    width: 200px;
    height: 10px;
    margin-right: -20px;
}

*/

/*#menu_id{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    text-align: center; 
}*/

