#ScheduleBackground{
    width: 100vw;
    /*background: url("../media/Images/packagesPart.png") no-repeat right top 20px;*/
    height: auto;
}


#scheduleSample{
  width: 100%;
  height: auto;
  background-size: cover;
}


.scheduleS {
  width: 100%;
  height: auto;
}
  