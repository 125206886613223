#headerPartDanceEdit{
    width: 100vw;
    height: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  #HeaderDanceEdit{
    width: 100vw;
    font-size: 50;
    height: 60px;
  }
  
  #tableDanceEdit{
    width: 90vw;
    margin: auto;
  }
  
  #danceEdit_button1{
    margin: 2px;
    background-color:rgb(150,22,22);
    width: 180px;
    font-size: larger;
    margin-left: 5vw;
  }
  
  #danceEdit_button1:hover{
    background-color:#9c0303;
  }
  
  .formHalfWidthDanceEdit{
    width: 50%;
  }
  .fullWidthFormDanceEdit{
    width: 100%;
  }

  #submitDanceEditContainer{
      display: flex;
      flex-direction:row;
      text-align: center;
  }
  
  
  #submitDanceEdit1{
      width: 31%;
    color: white;
    font-size: larger;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    background: rgb(150,22,22);
  }

  #submitDanceEdit2{
    width: 31%;
    color: white;
    font-size: larger;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    background: rgb(150,22,22);
  }

  #submitDanceEdit3{
    width: 31%;
    color: white;
    font-size: larger;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    background: rgb(150,22,22);
  }
  
  #addFormDanceEdit{
    margin: 5vw;
    margin-top: 0;
    width: 90vw;
  }
  
  #addDanceEdit{
    margin: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color:#262626;
  }
  
  #formDanceEdit{
    color: white;
  }
  
  @media (orientation: landscape){
    #formDanceEdit{
      width: 90%;
      color: white;
      font-size:1.3vw;
    }
  }
  
  @media (orientation: portrait){
    #formDanceEdit{
      width: 90%;
      color: white;
      font-size:2.5vw;
    }
  }
  
  