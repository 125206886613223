#headerPartInstructorEdit{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderInstructorEdit{
  width: 100vw;
  font-size: 50;
  height: 60px;
}



#profilePictureChangeInstructorEdit{
  width: 90vw;
  margin-left: 5vw;

}



#instructorEdit_button1{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#instructorEdit_button1:hover{
  background-color:#9c0303;
}

.formHalfWidthInstructorEdit{
  width: 50%;
}
.fullWidthFormInstructorEdit{
  width: 100%;
}

#submitInstructorEditContainer{
    display: flex;
    flex-direction:row;
    text-align: center;
}


#submitInstructorEdit1{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: rgb(150,22,22);
}

#submitInstructorEdit1:hover{
  background-color:#9c0303;
}

#submitInstructorEdit2{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: rgb(150,22,22);
}

#submitInstructorEdit2:hover{
  background-color:#9c0303;
}

#submitInstructorEdit3{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: rgb(150,22,22);
}

#submitInstructorEdit3:hover{
  background-color:#9c0303;
}

#submitInstructorEdit4{
  width: 100%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-top: 1%;
  background: rgb(150,22,22);
}

#submitInstructorEdit4:hover{
  background-color:#9c0303;
}

#addFormInstructorEdit{
  margin: 5vw;
  margin-top: 0;
  width: 90vw;
}

#addInstructorEdit{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}

#danceCatDropdownEdit{
  width: 40%;
}

#danceDropdownEdit{
  width: 40%;
}



@media (orientation: landscape){
  #formInstructorEdit{
    width: 90%;
    color: white;
    font-size:1.3vw;
  }
  #containerInstructorEdit{
    display: flex;
    flex-direction: row;
    float: left;
    margin-left: 5vw;
  }
  
  #cardInstructorEdit{
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    margin-top: 2%;
  }
  
  #addFormInstructorEdit{
    width: 75vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  #removeFieldEdit{
    display: flex;
    flex-direction: column;
    width: 7%;
    justify-content: center;
    justify-self: center;
    text-align: center;
  
  }
  #removeEdit{
    padding: 20%;
  }
}

@media (orientation: portrait){
  #formInstructorEdit{
    width: 90vw;
    color: white;
    font-size:2.5vw;
  }
  #containerInstructorEdit{
    display: flex;
    flex-direction: column;
  }
  
  #cardInstructorEdit{
    width: 200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #addFormInstructorEdit{
    width: 90vw;
    margin-left: 5vw;
  }

  #removeFieldEdit{
    display: flex;
    flex-direction: column;
    width: 14%;
    justify-content: center;
    justify-self: center;
    text-align: center;
  
  }
  #removeEdit{
    padding: 0%;
  }
}

#instructor_reset_password{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#instructor_reset_password:hover{
  background-color:#9c0303;
}


#resetPasswordInstructorEdit{
  margin: 5vw;
  width: 90vw;
}

#resetInstructorEdit{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}

#formResetPasswordInstructor{
  color: white;
}

#submitResetPasswordInstructor{
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: rgb(150,22,22);
}