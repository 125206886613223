#ClassesBackground{
    width: 100vw;
    background-image: url("../media/Images/ClassesBackground.png");
    background-size: cover;
}

#ClassesHeader{
    color: #313131;
    font-size: 70px;
    font-weight: bold;
}


@media (orientation: landscape) {
    .containerClass{
        display: flex;
        flex-flow: row wrap;
        justify-content:space-evenly;
        text-align: center;
        width: 100 vw;
        padding: 0;
        margin: auto;
        list-style: none;
    }
    
    .itemClass {
        flex-basis:  auto; /* default auto */
        margin: 1%;
        width: 400px;
        height: 400px;
        line-height: 400px;
        color: white;
        font-weight: bold;
        text-align: center;
        color: white;
        font-size: 30px;
        cursor: pointer;
        background-size: cover;
    
      }
    
      .itemClass:hover {
        color: rgba(255, 255, 255, 0);
        justify-content:space-between;
        font-size: x-large;
        cursor: pointer;
        background-size: cover;
      }
    
}

@media (orientation: portrait) {
    .containerClass{
        display: flex;
        flex-flow: row wrap;
        justify-content:space-evenly;
        text-align: center;
        width: 100vw;
        padding: 0;
        margin: 2vw;
        list-style: none;
    }
    .itemClass {
        flex-basis:  auto; /* default auto */
        margin: 1%;
        width: 150px;
        height: 150px;
        line-height: 150px;
        color: white;
        font-weight: bold;
        text-align: center;
        color: white;
        font-size: 60%;
        cursor: pointer;
        background-size: cover;
    
      }
    
    .itemClass:hover {
        color: rgba(255, 255, 255, 0);
        justify-content:space-between;
        font-size: x-large;
        cursor: pointer;
        background-size: cover;
    }
    
}

