#profile-pic{
    width:  200px; /*or 70%, or what you want*/
    height: 270px; /*or 70%, or what you want*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    background-color: #ffffff;
   
    /*
    justify-self: center;
    background-color:  rgb(219, 222, 223);*/
}

#cardSize{
    height: 500px;
    width: 500px;
    display: flex;
    flex-direction: column;
}
