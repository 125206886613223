#headerPartTrack{
    width: 100vw;
    height: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  #HeaderTrack{
    width: 100vw;
    font-size: 50;
    height: 60px;
  }
  
  #tableTrack{
    width: 90vw;
    margin: auto;
  }
  
  #track_button1{
    margin: 2px;
    background-color:rgb(150,22,22);
    width: 180px;
    font-size: larger;
    margin-left: 5vw;
  }
  
  #track_button1:hover{
    background-color:#9c0303;
  }
  
  .formHalfWidthTrack{
    width: 50%;
  }
  .fullWidthFormTrack{
    width: 100%;
  }
  
  
  #submitTrack{
    margin-top: 2%;
    margin-left: 80%;
    color: white;
    font-size: larger;
    padding: 1%;
    background: rgb(150,22,22);
  }
  
  #addFormTrack{
    margin: 5vw;
    width: 90vw;
  }
  
  #addTrack{
    margin: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color:#262626;
  }
  
  #formTrack{
    color: white;
  }
  
  @media (orientation: landscape){
    #formTrack{
      width: 90%;
      color: white;
      font-size:1.3vw;
    }
  }
  
  @media (orientation: portrait){
    #formTrack{
      width: 90%;
      color: white;
      font-size:2.5vw;
    }
  }
  
  