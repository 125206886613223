#FooterBackground {
  width: 100%;
  /*background: url("../media/Images/about us_.png") no-repeat;*/
  background-image: url("../media/Images/FooterBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  object-position: center;
  height: calc(((100vw) * 444 / 1920));
  text-align: center;
  justify-content: center;
  padding: auto;
}

#iconItem1 {
  float: left;
  border-radius: 50%;
  margin-left: 5vw;
  padding: 2vw;
  width: 10vw;
  height: 10vw;
  object-fit: contain;
  object-position: left;
  justify-items: baseline;
  background-color: black;
}

#iconItem2 {
  float: left;
  border-radius: 30%;
  margin-left: 5vw;
  padding: 2vw;
  width: 10vw;
  height: calc(10vw * 125/126);
  object-fit: contain;
  object-position: left;
  justify-items: baseline;
  background-color: black;
}

#iconItem3 {
  float: left;
  margin-top: 1.5vw;
  margin-left: 5vw;
  width: 45vw;
  height: 9vw;
  text-align: right;
  font-weight: bolder;
  font-size: 3vw;
  line-height: 4vw;
}

/* #iconItem4{
  margin-top: 1.5vw;
  margin-left: 1vw;
  float: left;
  width: 25vw;
  height:9vw;
  text-align: left;
  font-weight: normal;
  font-size: 3vw;
} */

p {
  margin: 0%;
}