#AboutUsBackground{
    width: 100vw;
    /*margin-top: 30px;*/
    /*background: url("../media/Images/about us_.png") no-repeat;*/
    background-image: url("../media/Images/about us_.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    object-fit: contain;
    object-position: center;
    height: calc(30px + ((100vw) * 1428 / 1918));
    text-align: center;
    justify-content: center;
    padding: auto;
}

.textAreaAbout{
  margin-top: calc(30px + ((100vw) * 1428 / 1918 / 4));
  background-color: rgba(255, 255, 255, 0%);
  color: rgba(255, 255, 255, 100%);
  width: 75%;
  height: calc(((75vw) / 2));
  line-height: 2.8vw;
  font-size: 2.8vw;
  border-style: none;
  text-align: center;
  justify-self: center;
}