#headerPartClassInfoStudent{
  width: 90vw;
  margin-left: 5vw;
  height: 30px;
  text-align: center;
}

#HeaderClassInfoStudent{
  height: 50px;
  width: 300px;
  font-size: 50;
  background-color: white;
  color: black;
  justify-content: center;
  text-align: center;
  padding: 5px;
}

#iconClassInfoStudent{
  display: inline;
  width:50px;
  padding-right: 5px;
  justify-self: center;
}

#tableClassInfoStudent{
  width: 90vw;
  margin: auto;
}

#stud_add_button1{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
  float: right;
}

#stud_add_button1:hover{
  background-color:#9c0303;
}
