#RegisterationPO{
    margin: 0.5%;
    padding: 2%;
}

#RegisterationSP{
    margin: 0.5%;
    padding: 2%;
}

#signIn{
    margin: 0.5%;
    padding: 2%;
}

#regSign{
    padding: 2%;
    width: 80%;
    float: right;
}

#signInButton{
    background-color: rgb(150,22,22);
}

#registerationButton{
    background-color: rgb(150,22,22);
}

#signInButton:hover{
    background-color: #d00808;
}

#registerationButton:hover {
    background-color: #d00808;
}

#signinsubmit{
    color: white;
    background-color: rgb(150,22,22);
}

#signinsubmit:hover {
    color: white;
    background-color: #d00808;
}

#signupsubmit{
    color: white;
    background-color: rgb(150,22,22);
}

#signupsubmit:hover {
    color: white;
    background-color: #d00808;
}

#propertyOwnerLogo{
    float: right;
    margin: 10px;
}
#serviceProviderLogo{
    float: right;
    margin: 10px;
}