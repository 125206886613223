#headerPartPartner{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderPartner{
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tablePartner{
  width: 90vw;
  margin: auto;
}

#partner_button1{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#partner_button1:hover{
  background-color:#9c0303;
}

.formHalfWidthPartner{
  width: 50%;
}
.fullWidthFormPartner{
  width: 100%;
}

#submitPartner{
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #740303;
}

#addFormPartner{
  margin: 5vw;
  width: 90vw;
}

#addPartner{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}

@media (orientation: landscape){
  #formPartner{
    width: 90%;
    color: white;
    font-size:1.3vw;
  }
}

@media (orientation: portrait){
  #formPartner{
    width: 90%;
    color: white;
    font-size:3.5vw;
  }
}
