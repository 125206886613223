#headerPartStudentAdd{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderStudentAdd{
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableStudentAdd{
  width: 90vw;
  margin: auto;
}

#student_add_button1{
  margin: 2px;
  background-color:rgb(150,22,22);
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
  float: right;
}

#student_add_button1:hover{
  background-color:#9c0303;
}
