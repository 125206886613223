#headerPartSubscription{
    width: 100vw;
    height: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  #HeaderSubscription{
    width: 100vw;
    font-size: 50;
    height: 60px;
  }
  
  #tableSubscription{
    width: 90vw;
    margin: auto;
  }
  
  #subscription_button1{
    margin: 2px;
    background-color:rgb(150,22,22);
    width: 180px;
    font-size: larger;
    margin-left: 5vw;
  }
  
  #subscription_button1:hover{
    background-color:#9c0303;
  }
  
  .formHalfWidthSubscription{
    width: 50%;
  }
  .fullWidthFormSubscription{
    width: 100%;
  }
  
  
  #submitSubscription{
    margin-top: 2%;
    margin-left: 80%;
    color: white;
    font-size: larger;
    padding: 1%;
    background: rgb(150,22,22);
  }
  
  #addFormSubscription{
    margin: 5vw;
    width: 90vw;
  }
  
  #addSubscription{
    margin: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color:#262626;
  }
  
  #formSubscription{
    color: white;
  }
  
  @media (orientation: landscape){
    #formSubscription{
      width: 90%;
      color: white;
      font-size:1.3vw;
    }
  }
  
  @media (orientation: portrait){
    #formSubscription{
      width: 90%;
      color: white;
      font-size:2.5vw;
    }
  }
  
  